.container {
  max-width: 200rem;
  padding: 0;

  .img {
    display: block;
    width: 100%;
    position: relative;
    z-index: 2;
  }
}



.header {
  .container {
    position: relative;

    width: 100%;
    margin: 0 auto;

    &::after,
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background-size: cover;
    }

    &::before {
      transform: translateX(-100%) scaleX(-1);
      left: 1px;
    }

    &::after {
      right: 1px;
      transform: translateX(100%) scaleX(-1);
    }

    &:first-child {
      &::before {
        background-image: url(../img/bg1.webp);
      }

      &::after {
        background-image: url(../img/bg1.webp);
      }
    }

    &:last-child {
      &::before {
        background-image: url(../img/bg2.webp);
      }

      &::after {
        background-image: url(../img/bg2.webp);
      }
    }
  }

}

.footer {
  .container {
    position: relative;
    width: 100%;
    margin: 0 auto;

    &::after,
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background-size: cover;
    }

    &::before {
      transform: translateX(-100%) scaleX(-1);
      left: 1px;
    }

    &::after {
      right: 5px;
      transform: translateX(100%) scaleX(-1);
    }

    &::before {
      background-image: url(../img/bg5.webp);

    }

    &::after {
      background-image: url(../img/bg5.webp);
    }
  }
}

.header__menu {
  width: 100%;
  position: fixed;
  z-index: 100;

  &:before {
    background-color: #009eda;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
}

.header-menu__body {
  position: absolute;
  display: flex;
  align-items: center;
  height: 70px;
  display: block;
}

.header__list-link {
  color: white;
  font-size: 28px;
  line-height: 34px;
}
.header__menu-item{
  margin-bottom: 10px;
}
.header-menu__body{
  display:none;
}



@media(max-width:480px) {
  .header {
    &-menu__body{
      display: block;
      position: fixed;
      top: 10px;
      left: 15px;
      z-index: 130;
    }
    &__burger {
      display: block;
      width: 32px;
      height: 32px;
      border: transparent;
      background: transparent;
    }

    &__burger-item {
      z-index: 104;
      width: 100%;
      height: 3px;
      background: #fff;
      position: relative;
      top: 0;
      left: 0;
      display: block;
    }

    &__burger-item+.header__burger-item {
      margin-top: 7px;
    }


    &__menu {
      position: fixed;
      height: 100%;
      width: 100%;
      left: -100%;
      top: 0;
      background: #009eda;
      transition: left 0.7s;
    }

    &__list {
      display: block;
      list-style-type: none;
      padding-left: 15px;
      padding-top: 70px;
    }
  }
}

.active{
.header__menu{
  left: 0;
}
}
