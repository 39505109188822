.section9 {
  .container{
    padding-top: 8.2rem;
    padding-left: 11rem;
    @media (max-width:800px) {
      padding-left: 0;
    }
  }

}

.planning{
  h2.title{
    margin-bottom: 0;
  }
  h3{
    font-size: 5.85rem;
    color: #3f3f3f;
    margin-top: 0;
    line-height: 1.07;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    @media (max-width:800px) {
      margin-bottom: 4rem;
    }
  }
  img{
    width: 190rem;
    transform: scale(1.03) translateX(-8.5rem) translateY(-0.9rem);
    @media (max-width:800px) {
      transform: none;
      object-position: right;
      object-fit: cover;
      height: 70rem;
    }
    @media (max-width:650px) {

      height: 55rem;
    }
    @media (max-width:550px) {
      height: 48rem;
    }
    @media (max-width:440px) {
      height: 41rem;
    }
  }
}

