.section {
  background-color: #F8FCFF;
  position: relative;

  .container {

   /* min-height: 112.6rem;*/
    padding-top: 9.2rem;
    padding-left: 12rem;
    position: relative;

    @media screen and (max-width: 800px) {
      height: auto;
      margin-bottom: 7.5rem;
    }



    &>* {
      position: relative;
      z-index: 2;
    }

    .img {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;

      //opacity: 0;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
    }

    &::before {
      background-image: url(../img/svg/bg3.svg);
      background-size: cover;
      z-index: 1;
      left: 0;
    }

    &::after {
      transform: translateX(84.9%);
      background-image: url(../img/svg/bg4.svg);
      background-size: cover;
      right: 0;
    }

    @media screen and (max-width: 800px) {

      &::after,
      &::before {
        display: none;
        background-color: #f7faf9;
      }
    }
  }

}

.section-back {
  .container {
    &::before {
      background-image: url(../img/svg/bg4.svg);
      background-size: cover;
      z-index: 1;
      left: 0;
    }

    &::after {
      background-image: url(../img/svg/bg4.svg);
      background-size: cover;
      right: inherit;
      transform: translateX(-84.9%) scaleX(-1);
    }
  }
}

h2 {
  &.title {
    font-size: 12.5rem;
    color: #00396E;
    margin-top: 0;
    font-weight: 900;
    line-height: 1.1;
    text-transform: uppercase;

    @media screen and (max-width: 800px) {
      font-size: 8rem;
      text-align: center;
      max-width: inherit;
      padding-right: 10rem;

      br {
        display: none;
      }
    }

    &+h3 {
      @media screen and (max-width: 800px) {
        text-align: center;
      }
    }
  }
}

/*section item*/
.section1 {
  @media screen and (max-width: 560px) {
    .container {
      padding-right: 11.5rem;
    }

  }

  h2 {
    &.title {
      max-width: 65rem;

      @media screen and (max-width: 800px) {
        max-width: inherit;
      }

      @media screen and (max-width: 560px) {
        padding-right: 0;

      }

      margin-bottom: 1.6rem;
    }
  }
}

.how-works__item {
  position: relative;

  &::after {
    content: "";
    background-image: url(../img/tm-corner.webp);
    background-size: cover;
    position: absolute;
    right: 0;
    top: 10.9375em;
    width: 9.8125em;
    height: 11.25em;
    transform: translateX(100%);

    @media screen and (max-width: 800px) {
      font-size: 1rem;
    }

  }

  &:last-child::after {
    display: none;
  }
}



.how-works__head {
  font-size: 3.88rem;
  color: #3e3e3e;
  font-weight: 600;
  max-width: 41rem;
  line-height: 1.2;
  min-height: 14.13rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;

  @media screen and (max-width: 800px) {
    min-height: inherit;
  }
}

.how-works__content {
  font-family: "Montserrat", sans-serif;
  font-size: 3.9rem;
  line-height: 1.2;
  max-width: 47rem;
  color: #00396e;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;
}


.how-works__list {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 620px) {
    flex-direction: column;
  }

  @media screen and (max-width: 560px) {
    gap: 20rem;
  }

}

.how-works__item {

  &:nth-child(1) {
    .how-works__img {
      text-align: center;
      margin: 0 auto;
      margin-bottom: 15px;
      padding-top: 10px;

      img {
        width: 13.2rem;
        height: 14.2rem;
      }
    }

    @media screen and (max-width: 800px) {
      width: 50%;

      &::after {
        right: inherit;
        left: 47rem;
        bottom: 0rem;
        top: inherit;
        transform: rotate(45deg);
      }
      .how-works__content{
          margin-bottom: 12.5rem;
          @media screen and (max-width: 560px) {
            margin-bottom: 5.5rem;
          }
      }
    }

    @media screen and (max-width: 560px) {
      margin: 0 auto;
      width: 49rem;

      &::after {
        transform: translate(-100%, 100%)rotate(90deg);
        left: 58%;
        bottom: -3rem;
      }

    }

  }

  &:nth-child(2) {
    width: 46rem;



    @media screen and (max-width: 800px) {
      margin-right: 4rem;
      order: 2;
      margin: 0 auto;
      transform: translateX(-3rem);

      &::after {
        transform: rotate(45deg);
        display: none;
      }
    }

    @media screen and (max-width: 620px) {
      order: inherit;
    }

    @media screen and (max-width: 560px) {
      margin: 0 auto;
      width: 43rem;
      transform: translateX(0);
    }

    .how-works__img {
      margin-top: 1rem;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 4rem;

      img {
        width: 14rem;
        height: 14rem;
      }
    }
  }

  &:nth-child(3) {
    margin-right: 9.8rem;


    @media screen and (max-width: 800px) {
      &::after {
        display: block;
        right: inherit;
        left: -20rem;
        bottom: 3rem;
        top: inherit;
        transform: rotate(-45deg);
      }
    }

    @media screen and (max-width: 620px) {
      margin-left: auto;
      margin-right: 0;

      &::after {
        left: -12rem;
        bottom: 3rem;
        top: 0;
        transform: rotate(45deg);
      }
    }

    @media screen and (max-width: 560px) {
      margin: 0 auto;
      width: 49rem;

      &::after {
        transform: translate(-100%, -100%) rotate(90deg);
        left: 57%;
        bottom: inherit;
        top: -3rem;
      }
    }

    .how-works__img {
      text-align: center;
    margin: 0 auto;
    margin-left: -4rem;
    margin-bottom: 3.75rem;
    padding-top: 20px;

      img {
        object-fit: contain;
        width: 13.2rem;
        height: 15.2rem;
        transform: translateY(-0.5rem);
      }
    }
  }

}



.how-works__img {
  margin-bottom: 5rem;
}

.ul-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}


.section2 {
  .container {
    padding-left: 9.7rem;
    padding-top: 8.4rem;
  }

  h2.title {
    margin-bottom: 9.5rem;
  }

  ul {
    margin-left: 2rem;
    font-size: 5rem;
    color: #4c4c4c;
    font-weight: 600;

    li {
      display: flex;
      align-items: flex-start;

      &:first-child {
        img {
          width: 19.8rem;
          height: 10.3rem;
        }
      }

      &:nth-child(2) {
        margin-top: 8.5rem;

        img {
          margin-top: 2rem;
          width: 20.1rem;
          height: 10.3rem;
        }

        div {
          margin-left: 4.3rem;

        }
      }

      &:last-child {
        margin-top: 9.75rem;

        img {
          width: 20.2rem;
          height: 12.5rem;
        }

        div {
          margin-left: 4.2rem;
          margin-top: 2rem;
        }
      }
    }

    div {
      margin-left: 4.8rem;

      p {
        margin-top: 3.2rem;
        font-size: 4.35rem;
      }

      small {
        display: block;
        font-size: 3rem;
        margin-top: 0.45rem;
      }
    }

  }

  .architecture {
    position: relative;

    .architecture-img-after{
    //&::after {
      content: "";
      background-image: url(../img/arhitek4.webp);
      background-size: cover;
      width: 52em;
      height: 52.985em;
      position: absolute;
      right: 6.5rem;
      bottom: 0;
    }

  .architecture-img-before {
    //&::before {
      z-index: 1;
      content: "";
      background-image: url(../img/arhitek5.webp);
      background-repeat: repeat-x;
      width: 13em;
      height: 72.65rem;
      position: absolute;
      right: -0.1rem;
      bottom: 5.79rem;

      @media screen and (max-width: 800px) {
        display: none;
      }
    }

    @media screen and (max-width: 800px) {
      max-width: 103rem;
      width: 100%;
      margin: 0 auto;

      .architecture-img-after {
     // &::after {
        position: static;
        display: block;
      }
    }
  }
}


.differences__text {
  line-height: 1.2;
  font-size: 3.9rem;
  color: #00396E;
  max-width: 135rem;

  @media screen and (max-width: 800px) {
    max-width: inherit;
    margin-right: 8rem;
  }
}

.differences__list {
  display: flex;
  position: relative;
  margin-left: 1rem;

  @media (max-width:800px) {
    flex-wrap: wrap;
  }


}

.differences__list-first {

  color: #00396E;

  &>div:first-child {
    font-size: 22.9rem;

    @media (max-width:600px) {
      font-size: 17.1rem;
    }

    font-weight: 900;
    line-height: .9;
    display: flex;

    &::after {
      content: "+";
      color: #00396E;
      font-weight: 700;
      transform: translateY(1.5rem);
    }
  }

  &>div:last-child {
    font-size: 9.1rem;

    margin-left: 1rem;
    line-height: 1;
    margin-top: 9rem;
    text-transform: lowercase;

    @media (max-width:800px) {
      margin-top: 0rem;
    }

    @media (max-width:600px) {
      font-size: 6.1rem;
      margin-left: 0;
    }
  }

  @media (max-width:600px) {
    text-align: center;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2rem;

    &>div:first-child {
      justify-content: center;
    }
  }
}

.differences {
  display: flex;
  flex-direction: column;
}

.differences__list .h3 {
  text-align: center;
  color: #00396E;
  font-weight: 600;
  font-size: 4.5rem;
  margin-top: 5.3rem;
  line-height: 1.2;
  margin-bottom: 3rem;

  @media (max-width:600px) {
    margin-bottom: 0 !important;
  }

}

.differences__list .h3~p {
  text-align: center;
  font-size: 3.34rem;
  color: #49423d;
  /*темно серый*/
}

.differences__list {
  li:first-child {
    @media (max-width:800px) {
      //width: 100%;
    }

  }

  li:nth-child(2) {
    margin-left: 5.5rem;

    @media (max-width:800px) {
      margin-left: 0;
    }

    .h3 {
      margin-bottom: 4.5rem;
      @media (max-width:800px) {
        margin-bottom: 3rem;
      }
    }

  }

  li:nth-child(3) {
    margin-left: 7.75rem;

    .h3 {
      margin-bottom: 9rem;
      @media (max-width:800px) {
        margin-bottom: 3rem;
      }
    }
  }

  li:nth-child(4) {
    margin-left: 8rem;

    .h3 {
      margin-bottom: 9rem;
      @media (max-width:800px) {
        margin-bottom: 3rem;
      }
    }
  }

  @media (max-width:800px) {
    max-width: 89rem;
    margin: 2rem auto 5rem;
    li {
      margin-left: 0 !important;
      width: 50%;
      /*&:nth-child(1) {
        .differences__list-first{
          display: flex;
          flex-direction: column;
          text-align: right;
          align-items: flex-end;
        }
      }

      &:nth-child(2) {

      }

      &:nth-child(3) {

      }

      &:nth-child(4) {

      }*/
    }
  }

  @media (max-width:600px) {
    li {
      margin-left: 0 !important;
      width: 45% !important;
    }

  }
}

.differences__img {
  display: flex;
  justify-content: flex-end;
  @media (max-width:800px) {
    justify-content: center;
  }
  padding-right: 10rem;

  img {
    width: 137rem;
  }
}



.section3 {
  .container {
    padding-left: 9.69rem;
  }
}


.wow{
  animation-duration: 2s;
}

.img-1{
  animation-duration: 5s;
  animation-name: heartBeat2;
 // animation-timing-function: cubic-bezier(0.62, 0.43, 0.39, 0.55);
}

@keyframes heartBeat2 {
  0% {
      transform: scale(1)
  }

  14% {
      transform: scale(1.1)
  }

  28% {
      transform: scale(1)
  }

  42% {
      transform: scale(1.1)
  }

  70% {
      transform: scale(1)
  }
}
