.section11{
  .container{
    padding-top: 7.4rem;
    padding-left: 10rem;
    @media(max-width:800px){
      padding-left: 0;
      h2.title{
        padding-right: 0;
      }
    }
  }

  h2.title{
    margin-bottom: 4rem;
    span{
      font-size: 12.7rem;
      transform: translateY(-1.8rem);
      display: inline-block;
      margin-left: 1rem;
    }

  }
}

.section11-img{
  width: 184.2rem;
  height: 79.8rem;
}

@media(max-width:790px){
  .section11-img{
    width: 170.2rem;
    height: inherit;
   transform: scale(1.01);
   margin: 0 auto;
    display: block;
  }
}
