@charset "UTF-8";
:root {
  --font-family: "Raleway", sans-serif;
  --content-width: 197rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */
html {
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  font-size: 62.5%;
}
@media screen and (max-width: 2000px) {
  html {
    font-size: 62%;
  }
}
@media screen and (max-width: 1980px) {
  html {
    font-size: 61.5%;
  }
}
@media screen and (max-width: 1960px) {
  html {
    font-size: 61%;
  }
}
@media screen and (max-width: 1940px) {
  html {
    font-size: 60.25%;
  }
}
@media screen and (max-width: 1920px) {
  html {
    font-size: 60%;
  }
}
@media screen and (max-width: 1900px) {
  html {
    font-size: 59.5%;
  }
}
@media screen and (max-width: 1880px) {
  html {
    font-size: 59%;
  }
}
@media screen and (max-width: 1860px) {
  html {
    font-size: 58%;
  }
}
@media screen and (max-width: 1840px) {
  html {
    font-size: 57%;
  }
}
@media screen and (max-width: 1820px) {
  html {
    font-size: 56.5%;
  }
}
@media screen and (max-width: 1800px) {
  html {
    font-size: 56%;
  }
}
@media screen and (max-width: 1780px) {
  html {
    font-size: 55.5%;
  }
}
@media screen and (max-width: 1760px) {
  html {
    font-size: 55%;
  }
}
@media screen and (max-width: 1740px) {
  html {
    font-size: 54%;
  }
}
@media screen and (max-width: 1720px) {
  html {
    font-size: 53%;
  }
}
@media screen and (max-width: 1700px) {
  html {
    font-size: 52%;
  }
}
@media screen and (max-width: 1680px) {
  html {
    font-size: 51%;
  }
}
@media screen and (max-width: 1660px) {
  html {
    font-size: 50%;
  }
}
@media screen and (max-width: 1620px) {
  html {
    font-size: 49%;
  }
}
@media screen and (max-width: 1570px) {
  html {
    font-size: 48%;
  }
}
@media screen and (max-width: 1550px) {
  html {
    font-size: 47%;
  }
}
@media screen and (max-width: 1530px) {
  html {
    font-size: 46%;
  }
}
@media screen and (max-width: 1500px) {
  html {
    font-size: 45%;
  }
}
@media screen and (max-width: 1450px) {
  html {
    font-size: 44%;
  }
}
@media screen and (max-width: 1430px) {
  html {
    font-size: 43%;
  }
}
@media screen and (max-width: 1400px) {
  html {
    font-size: 42%;
  }
}
@media screen and (max-width: 1360px) {
  html {
    font-size: 41%;
  }
}
@media screen and (max-width: 1320px) {
  html {
    font-size: 40%;
  }
}
@media screen and (max-width: 1300px) {
  html {
    font-size: 39%;
  }
}
@media screen and (max-width: 1280px) {
  html {
    font-size: 38%;
  }
}
@media screen and (max-width: 1260px) {
  html {
    font-size: 37%;
  }
}
@media screen and (max-width: 1240px) {
  html {
    font-size: 36%;
  }
}
@media screen and (max-width: 1200px) {
  html {
    font-size: 35%;
  }
}
@media screen and (max-width: 1180px) {
  html {
    font-size: 34%;
  }
}
@media screen and (max-width: 1160px) {
  html {
    font-size: 33%;
  }
}
@media screen and (max-width: 1140px) {
  html {
    font-size: 32%;
  }
}
@media screen and (max-width: 1120px) {
  html {
    font-size: 31%;
  }
}
@media screen and (max-width: 1100px) {
  html {
    font-size: 30%;
  }
}
@media screen and (max-width: 1000px) {
  html {
    font-size: 29%;
  }
}
@media screen and (max-width: 970px) {
  html {
    font-size: 28%;
  }
}
@media screen and (max-width: 930px) {
  html {
    font-size: 27%;
  }
}
@media screen and (max-width: 880px) {
  html {
    font-size: 26%;
  }
}
@media screen and (max-width: 850px) {
  html {
    font-size: 25%;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 32rem;
  min-height: 100%;
  font-size: 1.6rem;
}

body {
  font-size: 1.6rem;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
  width: 100%;
  background-color: #F8FCFF;
  z-index: 1;
  position: relative;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  /* фоновый цвет */
  background: #fff;
  z-index: 1001;
}

.preloader__image {
  position: relative;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  margin-top: -35px;
  margin-left: -35px;
  text-align: center;
  animation: preloader-rotate 2s infinite linear;
}

@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}
.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0;
}

.loaded .preloader {
  display: none;
}

.container {
  max-width: 200rem;
  padding: 0;
}
.container .img {
  display: block;
  width: 100%;
  position: relative;
  z-index: 2;
}

.header .container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.header .container::after, .header .container::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-size: cover;
}
.header .container::before {
  transform: translateX(-100%) scaleX(-1);
  left: 1px;
}
.header .container::after {
  right: 1px;
  transform: translateX(100%) scaleX(-1);
}
.header .container:first-child::before {
  background-image: url(../img/bg1.webp);
}
.header .container:first-child::after {
  background-image: url(../img/bg1.webp);
}
.header .container:last-child::before {
  background-image: url(../img/bg2.webp);
}
.header .container:last-child::after {
  background-image: url(../img/bg2.webp);
}

.footer .container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.footer .container::after, .footer .container::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-size: cover;
}
.footer .container::before {
  transform: translateX(-100%) scaleX(-1);
  left: 1px;
}
.footer .container::after {
  right: 5px;
  transform: translateX(100%) scaleX(-1);
}
.footer .container::before {
  background-image: url(../img/bg5.webp);
}
.footer .container::after {
  background-image: url(../img/bg5.webp);
}

.header__menu {
  width: 100%;
  position: fixed;
  z-index: 100;
}
.header__menu:before {
  background-color: #009eda;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.header-menu__body {
  position: absolute;
  display: flex;
  align-items: center;
  height: 70px;
  display: block;
}

.header__list-link {
  color: white;
  font-size: 28px;
  line-height: 34px;
}

.header__menu-item {
  margin-bottom: 10px;
}

.header-menu__body {
  display: none;
}

@media (max-width: 480px) {
  .header-menu__body {
    display: block;
    position: fixed;
    top: 10px;
    left: 15px;
    z-index: 130;
  }
  .header__burger {
    display: block;
    width: 32px;
    height: 32px;
    border: transparent;
    background: transparent;
  }
  .header__burger-item {
    z-index: 104;
    width: 100%;
    height: 3px;
    background: #fff;
    position: relative;
    top: 0;
    left: 0;
    display: block;
  }
  .header__burger-item + .header__burger-item {
    margin-top: 7px;
  }
  .header__menu {
    position: fixed;
    height: 100%;
    width: 100%;
    left: -100%;
    top: 0;
    background: #009eda;
    transition: left 0.7s;
  }
  .header__list {
    display: block;
    list-style-type: none;
    padding-left: 15px;
    padding-top: 70px;
  }
}
.active .header__menu {
  left: 0;
}

.navbar-custom {
  background-color: #F8FCFF;
  padding-left: 12rem;
  padding-right: 12rem;
  font-size: 3.1rem;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  box-shadow: 0px 0px 2rem rgba(127, 137, 161, 0.3);
}
@media (max-width: 1300px) {
  .navbar-custom {
    font-size: 3.9rem;
    padding-top: 0.5em;
    padding-bottom: 0.525em;
  }
}
@media (max-width: 700px) {
  .navbar-custom {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}
.navbar-custom .navbar-nav {
  transform: translateY(-0.08rem);
}
.navbar-custom .navbar-nav .nav-link {
  color: #3e3e3e;
}
.navbar-custom .navbar-nav .nav-link:hover {
  color: #000;
}
@media (min-width: 992px) {
  .navbar-custom .navbar-nav .nav-link {
    padding-right: 0.564em;
    padding-left: 0.564em;
  }
}
.navbar-custom .navbar-brand img {
  width: 30rem;
}
@media (max-width: 991px) {
  .navbar-custom .navbar-toggler {
    font-size: 4rem;
    padding: 1.25rem 1.75rem;
    z-index: 5;
  }
  .navbar-custom .nav-link {
    padding: 1.75rem 1rem;
  }
  .navbar-custom .navbar-collapse .navbar-nav {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
@media (min-width: 992px) {
  .navbar-custom .navbar-collapse {
    height: inherit !important;
  }
}

.footer {
  background-image: url(../img/bg5.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.footer__title {
  font-size: 33.25rem;
  color: white;
  text-align: center;
  margin-bottom: 12rem;
  margin-left: 4rem;
  font-weight: 900;
  margin-top: 0;
}
@media (max-width: 800px) {
  .footer__title {
    margin-left: 0;
  }
}
.footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 139rem;
  margin: 0 auto;
}
@media (max-width: 700px) {
  .footer-inner {
    justify-content: space-around;
  }
}
.footer__subtitle {
  color: #00396E;
  font-size: 5.52rem;
  line-height: 1.2;
  font-style: normal;
  margin-bottom: 7rem;
  font-weight: 400;
  margin-top: 0;
}
.footer .footer-inner__links {
  font-size: 3.4rem;
}
.footer__links-item {
  color: white;
}
.footer .container > * {
  z-index: 2;
  position: relative;
}
.footer .container {
  height: 112.6rem;
  padding-top: 15.83rem;
}
.footer .container img {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.footer-inner__left {
  margin-left: 7rem;
}

@media (max-width: 800px) {
  .footer__title {
    font-size: 25rem;
  }
}
@media (max-width: 600px) {
  .footer__title {
    font-size: 20rem;
  }
  .footer__subtitle {
    color: #00396E;
    font-size: 5rem;
    line-height: 1.2;
    font-style: normal;
    margin-bottom: 5rem;
    font-weight: 400;
    margin-top: 0;
  }
  .footer .footer-inner__links {
    font-size: 3rem;
  }
  .footer__links-item {
    color: white;
  }
}
@media (max-width: 450px) {
  .footer-inner__left {
    margin-left: 0;
  }
  .footer__title {
    font-size: 15rem;
  }
  .footer-inner {
    display: block;
    text-align: center;
    margin: 0 auto;
  }
  .footer__subtitle {
    color: #00396E;
    font-size: 5rem;
    line-height: 1.2;
    font-style: normal;
    margin-bottom: 5rem;
    font-weight: 400;
    margin-top: 0;
  }
  .footer .footer-inner__links {
    font-size: 3rem;
    margin-bottom: 30px;
    width: 100%;
  }
  .footer__links-item {
    color: white;
  }
}
.section {
  background-color: #F8FCFF;
  position: relative;
}
.section .container {
  /* min-height: 112.6rem;*/
  padding-top: 9.2rem;
  padding-left: 12rem;
  position: relative;
}
@media screen and (max-width: 800px) {
  .section .container {
    height: auto;
    margin-bottom: 7.5rem;
  }
}
.section .container > * {
  position: relative;
  z-index: 2;
}
.section .container .img {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.section .container::before, .section .container::after {
  content: "";
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.section .container::before {
  background-image: url(../img/svg/bg3.svg);
  background-size: cover;
  z-index: 1;
  left: 0;
}
.section .container::after {
  transform: translateX(84.9%);
  background-image: url(../img/svg/bg4.svg);
  background-size: cover;
  right: 0;
}
@media screen and (max-width: 800px) {
  .section .container::after, .section .container::before {
    display: none;
    background-color: #f7faf9;
  }
}

.section-back .container::before {
  background-image: url(../img/svg/bg4.svg);
  background-size: cover;
  z-index: 1;
  left: 0;
}
.section-back .container::after {
  background-image: url(../img/svg/bg4.svg);
  background-size: cover;
  right: inherit;
  transform: translateX(-84.9%) scaleX(-1);
}

h2.title {
  font-size: 12.5rem;
  color: #00396E;
  margin-top: 0;
  font-weight: 900;
  line-height: 1.1;
  text-transform: uppercase;
}
@media screen and (max-width: 800px) {
  h2.title {
    font-size: 8rem;
    text-align: center;
    max-width: inherit;
    padding-right: 10rem;
  }
  h2.title br {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  h2.title + h3 {
    text-align: center;
  }
}

/*section item*/
@media screen and (max-width: 560px) {
  .section1 .container {
    padding-right: 11.5rem;
  }
}
.section1 h2.title {
  max-width: 65rem;
  margin-bottom: 1.6rem;
}
@media screen and (max-width: 800px) {
  .section1 h2.title {
    max-width: inherit;
  }
}
@media screen and (max-width: 560px) {
  .section1 h2.title {
    padding-right: 0;
  }
}

.how-works__item {
  position: relative;
}
.how-works__item::after {
  content: "";
  background-image: url(../img/tm-corner.webp);
  background-size: cover;
  position: absolute;
  right: 0;
  top: 10.9375em;
  width: 9.8125em;
  height: 11.25em;
  transform: translateX(100%);
}
@media screen and (max-width: 800px) {
  .how-works__item::after {
    font-size: 1rem;
  }
}
.how-works__item:last-child::after {
  display: none;
}

.how-works__head {
  font-size: 3.88rem;
  color: #3e3e3e;
  font-weight: 600;
  max-width: 41rem;
  line-height: 1.2;
  min-height: 14.13rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;
}
@media screen and (max-width: 800px) {
  .how-works__head {
    min-height: inherit;
  }
}

.how-works__content {
  font-family: "Montserrat", sans-serif;
  font-size: 3.9rem;
  line-height: 1.2;
  max-width: 47rem;
  color: #00396e;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.how-works__list {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .how-works__list {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 620px) {
  .how-works__list {
    flex-direction: column;
  }
}
@media screen and (max-width: 560px) {
  .how-works__list {
    gap: 20rem;
  }
}

.how-works__item:nth-child(1) .how-works__img {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;
  padding-top: 10px;
}
.how-works__item:nth-child(1) .how-works__img img {
  width: 13.2rem;
  height: 14.2rem;
}
@media screen and (max-width: 800px) {
  .how-works__item:nth-child(1) {
    width: 50%;
  }
  .how-works__item:nth-child(1)::after {
    right: inherit;
    left: 47rem;
    bottom: 0rem;
    top: inherit;
    transform: rotate(45deg);
  }
  .how-works__item:nth-child(1) .how-works__content {
    margin-bottom: 12.5rem;
  }
}
@media screen and (max-width: 800px) and (max-width: 560px) {
  .how-works__item:nth-child(1) .how-works__content {
    margin-bottom: 5.5rem;
  }
}
@media screen and (max-width: 560px) {
  .how-works__item:nth-child(1) {
    margin: 0 auto;
    width: 49rem;
  }
  .how-works__item:nth-child(1)::after {
    transform: translate(-100%, 100%) rotate(90deg);
    left: 58%;
    bottom: -3rem;
  }
}
.how-works__item:nth-child(2) {
  width: 46rem;
}
@media screen and (max-width: 800px) {
  .how-works__item:nth-child(2) {
    margin-right: 4rem;
    order: 2;
    margin: 0 auto;
    transform: translateX(-3rem);
  }
  .how-works__item:nth-child(2)::after {
    transform: rotate(45deg);
    display: none;
  }
}
@media screen and (max-width: 620px) {
  .how-works__item:nth-child(2) {
    order: inherit;
  }
}
@media screen and (max-width: 560px) {
  .how-works__item:nth-child(2) {
    margin: 0 auto;
    width: 43rem;
    transform: translateX(0);
  }
}
.how-works__item:nth-child(2) .how-works__img {
  margin-top: 1rem;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 4rem;
}
.how-works__item:nth-child(2) .how-works__img img {
  width: 14rem;
  height: 14rem;
}
.how-works__item:nth-child(3) {
  margin-right: 9.8rem;
}
@media screen and (max-width: 800px) {
  .how-works__item:nth-child(3)::after {
    display: block;
    right: inherit;
    left: -20rem;
    bottom: 3rem;
    top: inherit;
    transform: rotate(-45deg);
  }
}
@media screen and (max-width: 620px) {
  .how-works__item:nth-child(3) {
    margin-left: auto;
    margin-right: 0;
  }
  .how-works__item:nth-child(3)::after {
    left: -12rem;
    bottom: 3rem;
    top: 0;
    transform: rotate(45deg);
  }
}
@media screen and (max-width: 560px) {
  .how-works__item:nth-child(3) {
    margin: 0 auto;
    width: 49rem;
  }
  .how-works__item:nth-child(3)::after {
    transform: translate(-100%, -100%) rotate(90deg);
    left: 57%;
    bottom: inherit;
    top: -3rem;
  }
}
.how-works__item:nth-child(3) .how-works__img {
  text-align: center;
  margin: 0 auto;
  margin-left: -4rem;
  margin-bottom: 3.75rem;
  padding-top: 20px;
}
.how-works__item:nth-child(3) .how-works__img img {
  object-fit: contain;
  width: 13.2rem;
  height: 15.2rem;
  transform: translateY(-0.5rem);
}

.how-works__img {
  margin-bottom: 5rem;
}

.ul-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

.section2 .container {
  padding-left: 9.7rem;
  padding-top: 8.4rem;
}
.section2 h2.title {
  margin-bottom: 9.5rem;
}
.section2 ul {
  margin-left: 2rem;
  font-size: 5rem;
  color: #4c4c4c;
  font-weight: 600;
}
.section2 ul li {
  display: flex;
  align-items: flex-start;
}
.section2 ul li:first-child img {
  width: 19.8rem;
  height: 10.3rem;
}
.section2 ul li:nth-child(2) {
  margin-top: 8.5rem;
}
.section2 ul li:nth-child(2) img {
  margin-top: 2rem;
  width: 20.1rem;
  height: 10.3rem;
}
.section2 ul li:nth-child(2) div {
  margin-left: 4.3rem;
}
.section2 ul li:last-child {
  margin-top: 9.75rem;
}
.section2 ul li:last-child img {
  width: 20.2rem;
  height: 12.5rem;
}
.section2 ul li:last-child div {
  margin-left: 4.2rem;
  margin-top: 2rem;
}
.section2 ul div {
  margin-left: 4.8rem;
}
.section2 ul div p {
  margin-top: 3.2rem;
  font-size: 4.35rem;
}
.section2 ul div small {
  display: block;
  font-size: 3rem;
  margin-top: 0.45rem;
}
.section2 .architecture {
  position: relative;
}
.section2 .architecture .architecture-img-after {
  content: "";
  background-image: url(../img/sc1.png);
  background-size: cover;
  width: 52em;
  height: 52.985em;
  position: absolute;
  right: 6.5rem;
  bottom: 0;
}
.section2 .architecture .architecture-img-before {
  z-index: 1;
  content: "";
  background-image: url(../img/arhitek5.webp);
  background-repeat: repeat-x;
  width: 13em;
  height: 72.65rem;
  position: absolute;
  right: -0.1rem;
  bottom: 5.79rem;
}
@media screen and (max-width: 800px) {
  .section2 .architecture .architecture-img-before {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .section2 .architecture {
    max-width: 103rem;
    width: 100%;
    margin: 0 auto;
  }
  .section2 .architecture .architecture-img-after {
    position: static;
    display: block;
  }
}

.differences__text {
  line-height: 1.2;
  font-size: 3.9rem;
  color: #00396E;
  max-width: 135rem;
}
@media screen and (max-width: 800px) {
  .differences__text {
    max-width: inherit;
    margin-right: 8rem;
  }
}

.differences__list {
  display: flex;
  position: relative;
  margin-left: 1rem;
}
@media (max-width: 800px) {
  .differences__list {
    flex-wrap: wrap;
  }
}

.differences__list-first {
  color: #00396E;
}
.differences__list-first > div:first-child {
  font-size: 22.9rem;
  font-weight: 900;
  line-height: 0.9;
  display: flex;
}
@media (max-width: 600px) {
  .differences__list-first > div:first-child {
    font-size: 17.1rem;
  }
}
.differences__list-first > div:first-child::after {
  content: "+";
  color: #00396E;
  font-weight: 700;
  transform: translateY(1.5rem);
}
.differences__list-first > div:last-child {
  font-size: 9.1rem;
  margin-left: 1rem;
  line-height: 1;
  margin-top: 9rem;
  text-transform: lowercase;
}
@media (max-width: 800px) {
  .differences__list-first > div:last-child {
    margin-top: 0rem;
  }
}
@media (max-width: 600px) {
  .differences__list-first > div:last-child {
    font-size: 6.1rem;
    margin-left: 0;
  }
}
@media (max-width: 600px) {
  .differences__list-first {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2rem;
  }
  .differences__list-first > div:first-child {
    justify-content: center;
  }
}

.differences {
  display: flex;
  flex-direction: column;
}

.differences__list .h3 {
  text-align: center;
  color: #00396E;
  font-weight: 600;
  font-size: 4.5rem;
  margin-top: 5.3rem;
  line-height: 1.2;
  margin-bottom: 3rem;
}
@media (max-width: 600px) {
  .differences__list .h3 {
    margin-bottom: 0 !important;
  }
}

.differences__list .h3 ~ p {
  text-align: center;
  font-size: 3.34rem;
  color: #49423d;
  /*темно серый*/
}

.differences__list li:nth-child(2) {
  margin-left: 5.5rem;
}
@media (max-width: 800px) {
  .differences__list li:nth-child(2) {
    margin-left: 0;
  }
}
.differences__list li:nth-child(2) .h3 {
  margin-bottom: 4.5rem;
}
@media (max-width: 800px) {
  .differences__list li:nth-child(2) .h3 {
    margin-bottom: 3rem;
  }
}
.differences__list li:nth-child(3) {
  margin-left: 7.75rem;
}
.differences__list li:nth-child(3) .h3 {
  margin-bottom: 9rem;
}
@media (max-width: 800px) {
  .differences__list li:nth-child(3) .h3 {
    margin-bottom: 3rem;
  }
}
.differences__list li:nth-child(4) {
  margin-left: 8rem;
}
.differences__list li:nth-child(4) .h3 {
  margin-bottom: 9rem;
}
@media (max-width: 800px) {
  .differences__list li:nth-child(4) .h3 {
    margin-bottom: 3rem;
  }
}
@media (max-width: 800px) {
  .differences__list {
    max-width: 89rem;
    margin: 2rem auto 5rem;
  }
  .differences__list li {
    margin-left: 0 !important;
    width: 50%;
    /*&:nth-child(1) {
      .differences__list-first{
        display: flex;
        flex-direction: column;
        text-align: right;
        align-items: flex-end;
      }
    }

    &:nth-child(2) {

    }

    &:nth-child(3) {

    }

    &:nth-child(4) {

    }*/
  }
}
@media (max-width: 600px) {
  .differences__list li {
    margin-left: 0 !important;
    width: 45% !important;
  }
}

.differences__img {
  display: flex;
  justify-content: flex-end;
  padding-right: 10rem;
}
@media (max-width: 800px) {
  .differences__img {
    justify-content: center;
  }
}
.differences__img img {
  width: 137rem;
}

.section3 .container {
  padding-left: 9.69rem;
}

.wow {
  animation-duration: 2s;
}

.img-1 {
  animation-duration: 5s;
  animation-name: heartBeat2;
}

@keyframes heartBeat2 {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.1);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(1);
  }
}
.payment-module {
  display: flex;
}
.payment-module p {
  color: #00396E;
  font-size: 3.88rem;
  line-height: 1.213;
  margin-bottom: 5rem;
}
.payment-module h2.title {
  margin-bottom: 11rem;
}

.payment-module {
  height: 100%;
}

.payment-module__img {
  margin-left: auto;
  margin-top: auto;
}
.payment-module__img img {
  width: 96.2rem;
  height: 91.6rem;
}
@media (max-width: 700px) {
  .payment-module__img {
    display: none;
  }
}

.section4 .container {
  padding-top: 10.2rem;
  padding-left: 11rem;
}

.section5 .container {
  padding-top: 6rem;
  padding-left: 10rem;
}
@media (max-width: 700px) {
  .section5 .container {
    padding-left: 0;
  }
  .section5 .container h2.title {
    padding-right: 0;
  }
}

.efficiency h2.title {
  margin-bottom: 1.5rem;
  margin-left: 1rem;
}
.efficiency img {
  width: 161.7rem;
}
.efficiency .mobile-img {
  display: none;
}
@media (max-width: 700px) {
  .efficiency img {
    display: none;
  }
  .efficiency .mobile-img {
    display: block;
    width: 62rem;
    margin: auto;
  }
}

.section6 .container {
  padding-top: 8.2rem;
  padding-left: 11rem;
}

.statistics__container {
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  padding-left: 1rem;
}
@media (max-width: 700px) {
  .statistics__container {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
}

.statistics h2.title {
  margin-bottom: 0;
}
.statistics p {
  font-size: 4.45rem;
  margin-bottom: 5.8rem;
}

.statistics__left img {
  margin-top: 3.6rem;
  width: 57.5rem;
  margin-left: 1rem;
  transform: translateX(-3rem);
}

.statistics__right img {
  width: 87.3rem;
  height: 76.3rem;
}

.section7 .container {
  padding-top: 8rem;
  padding-left: 11rem;
}
.section7 .statistics__container {
  padding-left: 0rem;
}
.section7 .statistics__right img {
  transform: translateY(1rem);
  width: 85.3rem;
  height: 74.3rem;
}

.statistics h3 {
  color: #3F3F3F;
  font-size: 3rem;
  font-size: 5.86rem;
  margin-top: 0;
  margin-bottom: 12rem;
}

.statistics ul {
  transform: translateX(-1rem);
}
.statistics ul li {
  position: relative;
  font-size: 3.75rem;
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 9rem;
}
.statistics ul li::before {
  content: "";
  display: inline-block;
  margin-right: 4rem;
  background-size: contain;
}
.statistics ul li:first-child::before {
  background-image: url(../img/section7-3.webp);
  width: 13rem;
  height: 12rem;
}
.statistics ul li:last-child::before {
  content: "";
  width: 13rem;
  height: 12rem;
  background-image: url(../img/section7-2.webp);
  background-repeat: no-repeat;
}

.section8 .container {
  padding-top: 9rem;
  padding-left: 11rem;
}
@media (max-width: 600px) {
  .section8 .container {
    padding-left: 0;
  }
}
.section8 h2.title {
  margin-bottom: 0;
}
@media (max-width: 600px) {
  .section8 h2.title {
    padding-right: 0;
  }
}

.format {
  display: flex;
  flex-direction: column;
}
.format img {
  margin-left: auto;
  width: 142rem;
  transform: translate(-4rem, -5rem);
}
@media (max-width: 600px) {
  .format img {
    transform: none;
  }
}

.section9 .container {
  padding-top: 8.2rem;
  padding-left: 11rem;
}
@media (max-width: 800px) {
  .section9 .container {
    padding-left: 0;
  }
}

.planning h2.title {
  margin-bottom: 0;
}
.planning h3 {
  font-size: 5.85rem;
  color: #3f3f3f;
  margin-top: 0;
  line-height: 1.07;
  position: relative;
  z-index: 2;
  margin-bottom: 0;
}
@media (max-width: 800px) {
  .planning h3 {
    margin-bottom: 4rem;
  }
}
.planning img {
  width: 190rem;
  transform: scale(1.03) translateX(-8.5rem) translateY(-0.9rem);
}
@media (max-width: 800px) {
  .planning img {
    transform: none;
    object-position: right;
    object-fit: cover;
    height: 70rem;
  }
}
@media (max-width: 650px) {
  .planning img {
    height: 55rem;
  }
}
@media (max-width: 550px) {
  .planning img {
    height: 48rem;
  }
}
@media (max-width: 440px) {
  .planning img {
    height: 41rem;
  }
}

.section10 .container {
  padding-top: 8.7rem;
  padding-left: 10.85rem;
}

.solution h2.title {
  margin-bottom: 0;
}
.solution h3 {
  font-size: 5.87rem;
  line-height: 1.1;
  margin-top: 0;
  color: #3f3f3f;
}

.solution__list {
  position: relative;
  min-height: 68rem;
  justify-content: flex-end;
  display: flex;
  padding-right: 6.5rem;
  padding-top: 2rem;
}
.solution__list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/section10.webp);
  background-size: cover;
  width: 84rem;
  height: 68.5rem;
  transform: scaleX(0.82) translate(-15.8rem, -3.7rem);
}

.solution_title {
  font-size: 5.1rem;
  color: #00396E;
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.solution_title img {
  width: 7.1rem;
  margin-right: 1.65rem;
  transform: translateY(-1rem);
}

.solution_li {
  font-size: 3.3rem;
  line-height: 1.2;
  position: relative;
}

.solution__item {
  display: flex;
  flex-direction: column;
}
.solution__item p {
  margin: 0;
}

.solution__item + .solution__item {
  margin-left: 17.25rem;
}
.solution__item:first-child .solution_title {
  margin-bottom: 2rem;
}
.solution__item:first-child .solution_li:nth-child(2) {
  margin-bottom: 4.5rem;
}
.solution__item:first-child .solution_li:nth-child(2) .decoration {
  left: -4rem;
  width: 11rem;
}
.solution__item:first-child .solution_li:nth-child(3) {
  margin-bottom: 4rem;
}
.solution__item:first-child .solution_li:nth-child(3) .decoration {
  width: 18.3rem;
  margin-top: 1rem;
  left: -3.75rem;
}
.solution__item:first-child .solution_li:nth-child(4) .decoration {
  left: -3.7rem;
  width: 28.75rem;
  margin-top: -1rem;
}
.solution__item:last-child > * {
  padding-left: 3rem;
}
.solution__item:last-child > *:last-child {
  padding-left: 0;
}
.solution__item:last-child .solution_title {
  margin-bottom: 4rem;
}
.solution__item:last-child .solution_li:nth-child(2) {
  margin-bottom: 17.5rem;
}
.solution__item:last-child .solution_li:nth-child(3) {
  margin-bottom: 10rem;
}
@media (max-width: 800px) {
  .solution__item:last-child .solution_li:nth-child(2) {
    margin-bottom: 4rem;
  }
  .solution__item:last-child .solution_li:nth-child(3) {
    margin-bottom: 4rem;
  }
}

.decoration {
  background-color: #00396E;
  position: absolute;
  top: 50%;
  left: -3rem;
  width: 10rem;
  height: 2px;
  transform: translateY(-50%) translateX(-100%);
  font-size: 4.2rem;
}
.decoration::after, .decoration::before {
  background-color: #00396E;
  content: "";
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.decoration::before {
  left: 0;
}
.decoration::after {
  right: 0;
}

@media (max-width: 800px) {
  .solution__list {
    display: block;
    margin: 0 auto;
  }
  .solution__list::before {
    background-image: none;
  }
  .decoration {
    display: none;
  }
  .solution_li {
    margin: 0 auto;
    text-align: center;
  }
  .solution__item + .solution__item {
    margin-left: 0;
    margin-top: 7.5rem;
  }
  .solution_title {
    text-align: center;
    margin: 0 auto;
  }
}
.section11 .container {
  padding-top: 7.4rem;
  padding-left: 10rem;
}
@media (max-width: 800px) {
  .section11 .container {
    padding-left: 0;
  }
  .section11 .container h2.title {
    padding-right: 0;
  }
}
.section11 h2.title {
  margin-bottom: 4rem;
}
.section11 h2.title span {
  font-size: 12.7rem;
  transform: translateY(-1.8rem);
  display: inline-block;
  margin-left: 1rem;
}

.section11-img {
  width: 184.2rem;
  height: 79.8rem;
}

@media (max-width: 790px) {
  .section11-img {
    width: 170.2rem;
    height: inherit;
    transform: scale(1.01);
    margin: 0 auto;
    display: block;
  }
}
.section12 .container {
  padding-top: 5.2rem;
  padding-left: 11rem;
}
.section12 h2.title {
  margin-bottom: 12rem;
}
.section12__items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 6rem;
}
.section12__items_item {
  text-align: center;
  margin-bottom: 30px;
}
.section12__item-image {
  width: 41rem;
  height: 41rem;
}
.section12__item-title {
  font-size: 6rem;
  margin: 0;
  font-weight: 400;
  margin-top: 3.9rem;
  line-height: 1.735;
}
.section12__item-caption {
  font-size: 4rem;
  color: #49423d;
  margin: 0;
  margin-top: 2rem;
}

@media (max-width: 800px) {
  .section12__items {
    flex-wrap: wrap;
    margin: 0 auto;
  }
}
@media (max-width: 450px) {
  .section12__items_item {
    width: 100%;
  }
}
.section13-inner__image img {
  width: 70rem;
  margin: 0 auto;
  text-align: center;
  transform: translate(-7.2rem, -4rem);
}
@media (max-width: 800px) {
  .section13-inner__image img {
    transform: none;
  }
}

.text-list__item {
  color: #49423d;
  font-style: bold;
  font-size: 4rem;
  margin-bottom: 5.3rem;
}

.text-list__item span {
  color: #00396E;
}

.section13 .container {
  padding-top: 8.5rem;
  padding-left: 1rem;
}
@media (max-width: 800px) {
  .section13 .container {
    padding-left: 0;
  }
}

.section_custom .section14-inner {
  display: flex;
  justify-content: space-between;
}
.section_custom__span {
  color: #00396E;
  font-size: 12rem;
}
.section_custom__title {
  color: #49423d;
  font-size: 5rem;
}
.section_custom-inner {
  display: flex;
  justify-content: space-between;
}
.section_custom__image {
  height: 80.7rem;
  margin-top: -11rem;
  width: 83rem;
}
.section_custom .text-list__item {
  color: #49423d;
  text-transform: capitalize;
  font-size: 4rem;
  margin-bottom: 4.87rem;
  display: flex;
}
@media (max-width: 800px) {
  .section_custom .text-list__item {
    width: 100%;
    max-width: 103rem;
    margin-right: auto;
    margin-left: auto;
  }
}
.section_custom .text-list__item span {
  font-weight: 600;
}
.section_custom .text-list__item span {
  color: #00396E;
}
.section_custom h2.title {
  margin-bottom: 0;
}
@media (max-width: 800px) {
  .section_custom h2.title {
    padding-right: 0;
  }
}
.section_custom h3 {
  font-size: 5.87rem;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 11.3rem;
  color: #3f3f3f;
}
.section_custom .text-list__item div {
  width: 44rem;
  text-align: right;
  font-size: 3.875rem;
}
.section_custom .text-list__item span {
  color: #1763a4;
  text-transform: lowercase;
  font-size: 3.9rem;
  padding-left: 3.75rem;
  text-align: left;
}

@media (max-width: 800px) {
  .section14-inner {
    display: block !important;
    margin: 0 auto;
    text-align: center;
  }
  .section_custom .text-list__item div {
    padding-left: 0;
  }
}
.section14 .container {
  padding-top: 8.3rem;
  padding-left: 1rem;
}
.section14 h3 {
  font-size: 5.93rem;
  text-transform: none;
}

.section14-inner__image img {
  width: 71.25rem;
  transform: translate(-6.65rem, -13.8rem);
}
@media (max-width: 800px) {
  .section14-inner__image img {
    transform: none;
  }
}

@media (max-width: 800px) {
  .section14-inner {
    display: block !important;
    margin: 0 auto;
    text-align: center;
  }
  .section_custom .text-list__item div {
    text-align: center;
  }
  .section_custom .text-list__item div {
    padding-left: 0;
  }
  .section14__title,
  .section14__subtitle {
    text-align: center;
    margin: 0 auto;
  }
}