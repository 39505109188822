.section12{

  .container{

    padding-top: 5.2rem;
    padding-left: 11rem;
  }
  h2.title{
    margin-bottom: 12rem;
  }

  &__items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 6rem;
  }
  &__items_item{
    text-align: center;
    margin-bottom: 30px;
  }
  &__item-image{
    width: 41rem;
    height: 41rem;
  }
  &__item-title{
    font-size: 6rem;
    margin: 0;
    font-weight: 400;
    margin-top: 3.9rem;
    line-height: 1.735;
  }
  &__item-caption{
    font-size: 4rem;
    color: #49423d;
    margin: 0;
     margin-top: 2rem;
  }
}


@media (max-width:800px) {
  .section12__items{
    flex-wrap: wrap;
    margin: 0 auto;
  }
}



@media (max-width:450px) {
  .section12__items_item{
    width: 100%;

  }

}

