.navbar-custom{
  background-color: #F8FCFF;
  padding-left: 12rem;
  padding-right: 12rem;
  font-size: 3.1rem;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  box-shadow: 0px 0px 2rem rgb(127 137 161 / 30%);

  @media (max-width: 1300px){
    font-size: 3.9rem;
    padding-top: 0.5em;
    padding-bottom: 0.525em;
  }
  @media (max-width: 700px){
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .navbar-nav{
    transform: translateY(-.08rem);
  }
  .navbar-nav .nav-link {
    color: #3e3e3e;
    &:hover{
      color: #000;
    }

    @media (min-width: 992px){
      padding-right: 0.564em;
      padding-left: 0.564em;
    }

  }

  .navbar-brand{
    img{
      width: 30rem;
    }
  }

  @media (max-width: 991px){
    .navbar-toggler {
      font-size: 4rem;
      padding: 1.25rem 1.75rem;
      z-index: 5;
    }

    .nav-link{
      padding: 1.75rem 1rem;
    }
    .navbar-collapse .navbar-nav{
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

  }

  //.nav-link{
  //  padding-top: 0;
  //}
  .navbar-collapse{
    @media (min-width: 992px){
      height: inherit !important;
    }
  }
}
