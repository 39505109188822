.section8 {
  .container{
    padding-top:9rem;
    padding-left: 11rem;
    @media (max-width:600px) {
      padding-left: 0;
    }
  }
  h2.title{
    margin-bottom: 0;
    @media (max-width:600px) {
      padding-right: 0;
    }
  }


}
.format{
  display: flex;
  flex-direction: column;
  img{
    margin-left: auto;
    width: 142rem;
    transform: translate(-4rem, -5rem);
    @media (max-width:600px) {
      transform: none;
    }
  }
}
