
.footer{
  background-image: url(../img/bg5.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center center;

 &__title{
  font-size: 33.25rem;
  color: white;
  text-align: center;
  margin-bottom: 12rem;
  margin-left: 4rem;
  font-weight: 900;
  margin-top: 0;
  @media(max-width:800px){
    margin-left: 0;
  }
 }
 &-inner{
  display: flex;
  justify-content:space-between;
  align-items: center;

  max-width: 139rem;
    margin: 0 auto;

    @media(max-width:700px){
      justify-content: space-around;
    }
 }

 &__subtitle{
  color: #00396E;
  font-size: 5.52rem;
  line-height: 1.2;
  font-style: normal;
  margin-bottom: 7rem;
  font-weight: 400;
  margin-top: 0;
 }
 .footer-inner__links{
  font-size: 3.4rem;
 }
 &__links-item{
  color: white;

 }

 .container > *{
  z-index: 2;
  position: relative;
 }
 .container{
  height: 112.6rem;
  padding-top: 15.83rem;
  img{
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
 }
}

.footer-inner__left{
  margin-left: 7rem;
}


@media(max-width:800px){
  .footer{

    &__title{
      font-size: 25rem;
    }
  }
}



@media(max-width:600px){
  .footer{

    &__title{
      font-size: 20rem;
    }
    &__subtitle{
      color: #00396E;
      font-size: 5rem;
      line-height: 1.2;
      font-style: normal;
      margin-bottom: 5rem;
      font-weight: 400;
      margin-top: 0;
     }
     .footer-inner__links{
      font-size: 3rem;
     }
     &__links-item{
      color: white;

     }

  }
}



@media(max-width:450px){
  .footer{
    &-inner__left {
      margin-left: 0;
  }
    &__title{
      font-size: 15rem;
    }
    &-inner{
      display: block;
      text-align: center;
      margin: 0 auto;

    }
    &__subtitle{
      color: #00396E;
      font-size: 5rem;
      line-height: 1.2;
      font-style: normal;
      margin-bottom: 5rem;
      font-weight: 400;
      margin-top: 0;
     }
     .footer-inner__links{
      font-size: 3rem;
      margin-bottom: 30px;
      width: 100%;


     }
     &__links-item{
      color: white;

     }

  }
}
