.section10 {
  .container{
    padding-top: 8.7rem;
    padding-left: 10.85rem;
  }

}

.solution{

  h2.title{
    margin-bottom: 0;
  }
  h3{
    font-size: 5.87rem;
    line-height: 1.1;
    margin-top: 0;
    color: #3f3f3f;
  }


}

.solution__list{
  position: relative;
  min-height: 68rem;
  justify-content: flex-end;
  display: flex;
  padding-right: 6.5rem;
  padding-top: 2rem;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../img/section10.webp);
    background-size: cover;
    width: 84rem;
    height: 68.5rem;
    transform: scaleX(0.82) translate(-15.8rem, -3.7rem);
  }
}

.solution_title{
  font-size: 5.1rem;
  color: #00396E;
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  img{
    width: 7.1rem;
    margin-right: 1.65rem;
    transform: translateY(-1rem);
  }
}
.solution_li{
  font-size: 3.3rem;
  line-height: 1.2;
  position: relative;
}
.solution__item{
  display: flex;
  flex-direction: column;
  p{
    margin: 0;
  }
}

.solution__item{
  & + .solution__item{
    margin-left: 17.25rem;
  }
  &:first-child{

    .solution_title{
      margin-bottom: 2rem;
    }

    .solution_li:nth-child(2){
      margin-bottom: 4.5rem;
      .decoration{
        left: -4rem;
        width: 11rem;
      }
    }
    .solution_li:nth-child(3) {
      margin-bottom: 4rem;
      .decoration{
        width: 18.3rem;
        margin-top: 1rem;
        left: -3.75rem;
      }
    }
    .solution_li:nth-child(4) {
      .decoration{
        left: -3.7rem;
        width: 28.75rem;
        margin-top: -1rem;
      }
    }


  }
  &:last-child{
    &>*{
      padding-left:3rem;
    }
    &>*:last-child{
      padding-left: 0;
    }

    .solution_title{
      margin-bottom: 4rem;
    }
    .solution_li:nth-child(2){
      margin-bottom: 17.5rem;
    }

    .solution_li:nth-child(3){
      margin-bottom: 10rem;
    }
    @media(max-width:800px){
      .solution_li:nth-child(2){
        margin-bottom: 4rem;
      }

      .solution_li:nth-child(3){
        margin-bottom: 4rem;
      }
    }
  }
}

.decoration{

  background-color: #00396E;
  position: absolute;
  top: 50%;
  left: -3rem;
  width: 10rem;
  height: 2px;
  transform: translateY(-50%) translateX(-100%);
  font-size: 4.2rem;
  &::after, &::before{

    background-color: #00396E;
    content: "";
    width: .5em;
    height: .5em;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

  }

   &::before{
    left: 0;
  }

  &::after{
    right: 0;
  }

}


@media(max-width:800px){
  .solution__list{
    display: block;
    margin: 0 auto;
  }
  .solution__list::before{
    background-image: none;
  }
  .decoration{
    display: none;
  }
  .solution_li{
    margin: 0 auto;
    text-align: center;
  }
  .solution__item + .solution__item{
    margin-left: 0;
    margin-top: 7.5rem;
  }
  .solution_title{
    text-align: center;
    margin: 0 auto;
  }
}
