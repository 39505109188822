.section13-inner__image{
  img{
    width: 70rem;
    margin:0 auto;
    text-align: center;
    transform: translate(-7.2rem, -4rem);
    @media(max-width:800px){
      transform: none;
    }
  }
}

.text-list__item{
  color: #49423d;
  font-style: bold;
  font-size: 4rem;
  margin-bottom: 5.3rem;
}
.text-list__item span{
  color: #00396E;
}

.section13{
  .container{
    padding-top: 8.5rem;
    padding-left: 1rem;
    @media(max-width:800px){
      padding-left: 0;
    }
  }


}

.section_custom{
  .section14-inner{
    display: flex;
    justify-content: space-between;
  }

  &__span{
    color: #00396E;
    font-size: 12rem;
  }
  &__title{
    color: #49423d;
    font-size: 5rem;
  }
  &-inner{
    display: flex;
    justify-content: space-between;
  }
  &__image{
    height: 80.7rem;
    margin-top: -11rem;
    width: 83rem;
  }

  .text-list__item{
    color: #49423d;
    text-transform: capitalize;
    font-size: 4rem;
    margin-bottom: 4.87rem;
    display: flex;

    @media(max-width:800px){
      width: 100%;
      max-width: 103rem;
      margin-right: auto;
      margin-left: auto;

    }

    span{
      font-weight: 600;

    }
  }
  .text-list__item span{
    color: #00396E;
  }



  h2.title{
    margin-bottom: 0;

    @media(max-width:800px){

        padding-right: 0;

    }
  }
  h3{
    font-size: 5.87rem;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 11.3rem;
    color: #3f3f3f;
  }
  .text-list__item{
    div{
      width: 44rem;
      text-align: right;
      font-size: 3.875rem;
    }
    span{
      color: #1763a4;
      text-transform: lowercase;
      font-size: 3.9rem;
      padding-left: 3.75rem;
      text-align: left;
    }
  }

}

@media(max-width:800px){
  .section14-inner{
    display: block !important;
    margin: 0 auto;
    text-align: center;
  }
.section_custom .text-list__item div{
  padding-left: 0;
}
}
