html {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  font-size: 62.5%;
  @media screen and (max-width: 2000px){
    font-size: 62%;
  }
  @media screen and (max-width: 1980px){
    font-size: 61.5%;
  }
  @media screen and (max-width: 1960px){
    font-size: 61%;
  }
  @media screen and (max-width: 1940px){
    font-size: 60.25%;
  }

  @media screen and (max-width: 1920px){
    font-size: 60%;
  }

  @media screen and (max-width: 1900px){
    font-size: 59.5%;
  }
  @media screen and (max-width: 1880px){
    font-size: 59%;
  }

  @media screen and (max-width: 1860px){
    font-size: 58%;
  }

  @media screen and (max-width: 1840px){
    font-size: 57%;
  }

  @media screen and (max-width: 1820px){
    font-size: 56.5%;
  }
  @media screen and (max-width: 1800px){
    font-size: 56%;
  }

  @media screen and (max-width: 1780px){
    font-size: 55.5%;
  }

  @media screen and (max-width: 1760px){
    font-size: 55%;
  }

  @media screen and (max-width: 1740px){
    font-size: 54%;
  }

  @media screen and (max-width: 1720px){
    font-size: 53%;
  }
  @media screen and (max-width: 1700px){
    font-size: 52%;
  }

  @media screen and (max-width: 1680px){
    font-size: 51%;
  }
  @media screen and (max-width: 1660px){
    font-size: 50%;
  }

  @media screen and (max-width: 1620px){
    font-size: 49%;
  }

  @media screen and (max-width: 1570px){
    font-size: 48%;
  }

  @media screen and (max-width: 1550px){
    font-size: 47%;
  }
  @media screen and (max-width: 1530px){
    font-size: 46%;
  }

  @media screen and (max-width: 1500px){
    font-size: 45%;
  }

  @media screen and (max-width: 1450px){
    font-size: 44%;
  }
  @media screen and (max-width: 1430px){
    font-size: 43%;
  }
  @media screen and (max-width: 1400px){
    font-size: 42%;
  }

  @media screen and (max-width: 1360px){
    font-size: 41%;
  }

  @media screen and (max-width: 1320px){
    font-size: 40%;
  }

  @media screen and (max-width: 1300px){
    font-size: 39%;
  }

  @media screen and (max-width: 1280px){
    font-size: 38%;
  }
  @media screen and (max-width: 1260px){
    font-size: 37%;
  }

  @media screen and (max-width: 1240px){
    font-size: 36%;
  }
  @media screen and (max-width: 1200px){
    font-size: 35%;
  }
  @media screen and (max-width: 1180px){
    font-size: 34%;
  }
  @media screen and (max-width: 1160px){
    font-size: 33%;
  }

  @media screen and (max-width: 1140px){
    font-size: 32%;
  }

  @media screen and (max-width: 1120px){
    font-size: 31%;
  }

  @media screen and (max-width: 1100px){
    font-size: 30%;
  }
  @media screen and (max-width: 1000px){
    font-size: 29%;
  }

  @media screen and (max-width: 970px){
    font-size: 28%;
  }
  @media screen and (max-width: 930px){
    font-size: 27%;
  }

  @media screen and (max-width: 880px){
    font-size: 26%;
  }
  @media screen and (max-width: 850px){
    font-size: 25%;
  }

}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 32rem;
  min-height: 100%;
  font-size: 1.6rem;
}

body{
  font-size: 1.6rem;
}
img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
  width: 100%;
  background-color: #F8FCFF;
  z-index: 1;
  position: relative;

}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}



// #preloader{
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: #fff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 3;
// }

// #preloader.hide-preloader{
//  animation: hidePreloader 1s;
// }
// #preloader.preloader-off{
//   display: none;
// }

// @keyframes hidePreloader{
//   0%{
//     opacity: 1;
//   }
//   100%{
//     opacity: 0;
//   }
// }
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  /* фоновый цвет */
  //background: #e0e0e0;

  background: #fff;
  z-index: 1001;
}

.preloader__image {
  position: relative;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  margin-top: -35px;
  margin-left: -35px;
  text-align: center;
  animation: preloader-rotate 2s infinite linear;
}

@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0;
}

.loaded .preloader {
  display: none;
}
