.section14 {
  .container {
    padding-top: 8.3rem;
    padding-left: 1rem;
  }
  h3 {
    font-size: 5.93rem;
    text-transform: none;
  }
}
.section14-inner__image img {
  width: 71.25rem;
  transform: translate(-6.65rem, -13.8rem);
  @media(max-width:800px){
    transform: none;
  }
}



@media(max-width:800px){
  .section14-inner{
    display: block !important;
    margin: 0 auto;
    text-align: center;
  }
.section_custom .text-list__item div{
text-align: center;
}
.section_custom .text-list__item div{
  padding-left: 0;
}
.section14__title,
.section14__subtitle {
  text-align: center;
  margin: 0 auto;
}
}

