// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  // font-family: 'Montserrat', sans-serif;
  --font-family: 'Raleway', sans-serif;
  --content-width: 197rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
}
